<template>
	<div>
		<div class="content">
			<el-row :gutter="20">
				<el-col :span="15">
					<div class="select-left">
						<div class="select-item" v-for="(item, index) in filters" :key="index">
							<el-select v-model="item.value" :placeholder="item.placeholder"
								v-if="item.filterType === 'select'" @change="selectChange(item)"
								:multiple="item.isMultiple" collapse-tags style="margin-right: 20px;">
								<el-option v-for="itm in item.options" :key="itm.pom_id"
									:label="item.lab ? itm[item.lab] : itm.pom_name"
									:value="item.val ? itm[item.val] : itm.pom_id">
								</el-option>
							</el-select>
							<KeyBoard ref="keyBoardRef" style="margin-left: 10px;" @inputEvent="inputEvent"
								v-if="item.filterType === 'search'" v-model="item.value" :placeholder="item.placeholder"
								:isSearchIcon="true">
							</KeyBoard>
						</div>
					</div>
					<div>
						<el-scrollbar style="height: 80px;">
							<div class="scrollbar-flex-content">
								<p v-for="(item,i) in shopTypeList" @click="checkShopType(item,i)"
									:class="{'active':i == activeItem}" :key="i" class="scrollbar-item round">
									{{ item.poc_name }}
								</p>
							</div>
						</el-scrollbar>
						<el-scrollbar style="height:calc(100vh - 320px)">
							<div class="grid-4">
								<div class="shop-card" v-for="(item,i) in shopList" :key="i" @click="addCart(item)">
									<div class="img">
										<img :src="item.goodsFile?item.goodsFile.file_url:''" />
									</div>
									<div class="text-center title">
										{{item.title}}
									</div>
									<div class="text-center price">
										￥{{item.price}}
									</div>
									<div class="text-center">
										<ShoppingCartFull class="icon" />
									</div>
								</div>
							</div>
						</el-scrollbar>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="cart">
						<div class="cart-title">
							<span>购物车</span>
							<span>已选{{allNum}}件</span>
						</div>
						<div v-if="cartList.length != 0">
							<el-scrollbar style="height: calc(100vh - 410px);overflow-x: hidden;">
								<div class="cart-item border-bottom" v-for="(item,i) in cartList" :key="i">
									<div class="flex">
										<div class="img">
											<img :src="item.goodsFile?item.goodsFile.file_url:''" />
										</div>
										<div>
											<div class="text-center title">
												{{item.title}}
											</div>
											<div class="text-center price">
												￥{{item.price}}
											</div>
										</div>
									</div>
									<div class="flex-center">
										<el-input-number v-model="item.buy_no" :min="1" :max="1000000000" />
									</div>
									<div class="move" @click="delCart(item, i)">
										<img src="@/assets/img/icon/remove-icon.png" />
									</div>
								</div>
							</el-scrollbar>
							<div class="statistics">
								<div class="flex-btw">
									<span class="text-gray">共计</span>
									<span class="skin-text-color">￥{{allMoney}}</span>
								</div>
								<div class="flex-btw padding border-bottom">
									<span class="text-gray">优惠</span>
									<span class="skin-text-color">0</span>
								</div>
								<div class="flex-btw padding">
									<span class="text-gray">合计</span>
									<span class="text-red">￥{{allMoney}}</span>
								</div>
								<div class="flex-center">
									<el-button type="primary" plain round @click="onShoppingBtn">添加至购物车</el-button>
									<el-button type="danger" round class="submit" @click="submit">结算</el-button>
								</div>
							</div>
						</div>
						<div v-else style="height: calc(100vh - 240px);">
							<div class="flex-center">
								<img src="@/assets/img/sale/cart-empty.png" />
							</div>
							<div class="flex-center text-red">暂无添加任何商品</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>


		<el-dialog v-model="centerDialogVisible" title="结算" width="50%" destroy-on-close center>
			<div class="dialog-pay" v-loading="payTypeLoading">
				<h2 class="text-center">￥{{allMoney}}</h2>
				<div class="text-center skin-text-color">请选择付款方式</div>
				<div class="grid-5 pay-type">
					<div v-for="(item,i) in payType" @click="checkPayType(item,i)" :key="i" style="margin: 0 auto;">
						<img :src="item.icon" class="pay-icon" />
						<div class="text-center skin-text-color">{{item.orp_name}}</div>
					</div>
				</div>
			</div>

		</el-dialog>

		<StatusDialog ref="loadingDialog" :type="1" :title="lastPayway ? '等待支付中' : '确认订单中'" :btnWidth="'200px'"
			@lConfirm="CloseLoadingDialog" :hideFooter="true" :isShowBtn="true">
			<div class="status-tips">
				<div v-if="lastPayway">
					请提醒游客使用 <span>{{ lastPayway }}</span> 支付·
				</div>
				<el-input style="margin: 10px 10px 15px ;" v-if="lastPayway && lastPayway != '现金'" class="barcode-input"
					v-model="barCodeInput" ref="barCodeInputRef" @keyup.enter="barCodeInfo" :autofocus="true"
					placeholder="请将光标聚焦在输入框内扫码收款"></el-input>
				<div class="flex-center" @click="cancelPayOrder">
					<el-button type="danger" class="text-red" plain round>取消支付</el-button>
				</div>
			</div>
		</StatusDialog>

		<el-dialog v-model="succesDialog" width="35%" destroy-on-close center>
			<div class="flex-center">
				<img src="@/assets/img/sale/pay-success.png" />
			</div>
			<div class="flex-center">支付成功</div>
			<div class="flex-center padding">{{countdown}}s后将自动跳转到点单页</div>
			<div class="flex-center" @click="cash">
				<el-button type="danger" round>确定</el-button>
			</div>
		</el-dialog>


		<el-dialog v-model="cashDialog" title="结算" width="50%" destroy-on-close center @close="closeCash">
			<div class="dialog-pay">
				<h2 class="text-center font-bold">￥{{allMoney}}</h2>
			</div>
			<el-form label-position="top" label-width="100px" :inline="true" :model="formLabelAlign"
				style="margin-top: 20px;margin-bottom: 100px;">
				<el-row>
					<el-col :span="12">
						<el-form-item label="应收金额">
							<el-input v-model="allMoney" readonly />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="实收金额">
							<!-- <el-input v-model="formLabelAlign.netReceipts" /> -->
							<KeyBoard v-model="formLabelAlign.netReceipts" placeholder="请输入实收金额">
							</KeyBoard>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="找零">
							<el-input v-model="givesChange" readonly />
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>

			<div class="flex-center" @click="cash">
				<el-button type="danger" round>确定</el-button>
			</div>
		</el-dialog>

		<el-dialog v-model="errorDialog" title="结算" width="50%" destroy-on-close center>
			<h2 class="text-center">￥{{allMoney}}</h2>
			<div class="flex-center">
				<img src="@/assets/img/sale/pay-error.png" class="img-icon" />
			</div>
			<div class="flex-center">{{errorTitle}}</div>

			<div class="flex-center margin">
				<div class="flex-center">
					<el-button type="danger" class="text-red" plain round @click="cancelOrder">取消订单</el-button>
				</div>
				<div class="flex-center margin-left">
					<el-button type="danger" round @click="againOrder">重新发起支付</el-button>
				</div>
			</div>
		</el-dialog>

		<PrintInfo ref="printInfoRef"></PrintInfo>

		<!-- 购物车 -->
		<shoppingCart :isShowShopCart="isShowShopCart" :badgeVal="badgeVal" @onClickCart="onClickCart"></shoppingCart>

		<!-- 购物车明细 -->
		<shopCartDetails ref="shopCartRef" @onSettlement="onSettlement" @onRetrieve="getShopCartList"></shopCartDetails>

		<!-- 购物车支付 -->
		<shopCartSettlement ref="shopCartSettle" @obtainShopData="obtainShopData">
		</shopCartSettlement>
	</div>
</template>

<script setup>
	import {
		ref,
		nextTick,
		onMounted,
		reactive,
		computed
	} from 'vue'
	import {
		ShoppingCartFull
	} from "@element-plus/icons-vue";

	import {
		SaleApi,
		ScenicApi,
		OrderApi,
	} from '@/plugins/api.js'
	import {
		ElMessage
	} from 'element-plus'
	import StatusDialog from "@/components/status-dialog/status-dialog.vue";
	import KeyBoard from "@/components/key-board/keyboard.vue";
	import PrintInfo from "@/components/print-info/print.vue";
	import shoppingCart from "@/components/shopping-cart/shoppingCart.vue";
	import shopCartDetails from "../components/shopCartDetails.vue";
	import shopCartSettlement from "../components/shopCartSettlement.vue";
	// import { useRouter, } from "vue-router";


	document.addEventListener('mousewheel', function (e) {
		e = e || window.event;
		if ((e.wheelDelta && event.ctrlKey) || e.detail) {
			event.preventDefault();
		}
	}, {
		capture: false,
		passive: false
	});

	document.addEventListener('keydown', function (event) {
		if ((event.ctrlKey === true || event.metaKey === true) &&
			(event.keyCode === 61 || event.keyCode === 107 ||
				event.keyCode === 173 || event.keyCode === 109 ||
				event.keyCode === 187 || event.keyCode === 189)) {
			event.preventDefault();
		}
	}, false);

	// const router = useRouter();  // 路由
	const isShowShopCart = ref(false);  // 是否显示购物车
	const badgeVal = ref(0);  // 徽章
	const shopCartRef = ref(null);
	const total_money = ref(0);
	const shopCartSettle = ref(null);
	/**
	 * 
	 * 点击购物车按钮
	 * 
	 * **/
	const onShoppingBtn = () => {
		let params = {
			ticket_data: [],
			type: 1,
			goods_data: [],
		};
		cartList.value.forEach(item => {
			params.goods_data.push({
				goods_id: item.goods_id,
				buy_no: item.buy_no,
			});
		})
		// console.log("params", params);
		SaleApi.addShopCart(params).then(res => {
			if (res.Code == 200) {
				ElMessage.success("购物车添加成功");
				cartList.value = [];
			} else {
				ElMessage.error(res.Message);
			}
			getShopCartList();
		})
	}
	/**
	 * 
	 * 获取购物车数据
	 * 
	 * **/
	const getShopCartList = () => {
		SaleApi.getShopCartList().then(res => {
			if (res.Code == 200) {
				badgeVal.value = res.Data.list ? res.Data.list.length : 0;
				total_money.value = res.Data.total_money;
				isShowShopCart.value = badgeVal.value > 0;
			} else {
				ElMessage.error(res.Message);
			}
		})
	}
	/**
	 * 
	 * 点击购物车图标
	 * 
	 * **/
	const onClickCart = () => {
		shopCartRef.value.openDialog();
		// nextTick(()=>{
		// 	shopCartRef.value.openDialog();
		// })
		// sessionStorage.setItem("isShopping", true);
		// router.push("/scenic/sale/olsale");
	}
	/**
	 * 
	 * 结算
	 * 
	 * **/
	const onSettlement = (val) => {
		shopCartSettle.value.openDialog(val);
		// centerDialogVisible.value = true;
	}
	/**
	 * 
	 * 下单成功
	 * 
	 * **/
	const obtainShopData = (type) => {
		if (type == '1') {
			// 关闭购物车明细弹框
			shopCartRef.value.closeDialog();
			getShop();
		} else {
			// 获取购物车数据
			getShopCartList();
		}
	}

	const formLabelAlign = reactive({
		netReceipts: '',
	})
	const inputEvent = () => {
		reqShop.poc_id = '';
		activeItem.value = 0;
		getShop()
	}


	const givesChange = computed(() => {
		if (Number(formLabelAlign.netReceipts) > Number(allMoney.value)) {
			return (Number(formLabelAlign.netReceipts) - Number(allMoney.value)).toFixed(2)
		} else {
			return 0
		}
	})
	const countdown = ref(5)
	const countdownTimer = ref(null)
	const startTimer = () => {
		countdown.value = 5
		countdownTimer.value = setInterval(() => {
			countdown.value--
			if (countdown.value < 1) {
				clearInterval(countdownTimer.value)
				cartList.value = [];
				cashDialog.value = false;
				succesDialog.value = false;
			}
		}, 1000)
	}

	//现金结算
	const cash = () => {
		clearInterval(countdownTimer.value)
		cartList.value = [];
		cashDialog.value = false;
		succesDialog.value = false;
		lodopSn()
	}
	const closeCash = () => {
		clearInterval(countdownTimer.value)
		cartList.value = [];
		cashDialog.value = false;
		succesDialog.value = false;
	}

	const filters = ref([{
		filterType: "select",
		name: "poc_sid",
		value: "",
		placeholder: "请选择门店",
		options: [],
		val: "pom_id",
		lab: "pom_name",
	},
	{
		filterType: "search",
		name: "keyword",
		value: "",
		placeholder: "输入名称进行搜索",
	},
	]);
	onMounted(async () => {
		getOrderType()
		await SaleApi.getMymerchant({
			pom_sid: '',
			pom_status: 1,
			pom_type: 0
		}).then(res => {
			if (res.Code == 200) {
				filters.value[0].options = res.Data;
				filters.value[0].value = filters.value[0].options[0].pom_id
			}
		})
		await getGoodstype()
		await getShop()
		await getShopCartList();
	})
	const reqShop = reactive({
		poc_status: 0,
		poc_id: '',
		pom_id: '',

	})
	const selectChange = async () => {
		cartList.value = []
		await getGoodstype()
		await getShop()
	}
	const shopTypeList = ref([])
	const getGoodstype = () => {
		shopTypeList.value = []
		SaleApi.getGoodtype({
			poc_status: reqShop.poc_status,
			pom_id: filters.value[0].value,
		}).then(res => {
			if (res.Code == 200) {
				if (res.Data && res.Data.length > 0) {
					shopTypeList.value = res.Data;
					shopTypeList.value.unshift({
						poc_id: '',
						poc_name: '全部'
					})
					reqShop.poc_id = shopTypeList.value[0].poc_id;
				}
			}
		})
	}

	const allMoney = computed(() => {
		let total = 0
		if (cartList.value.length != 0) {
			cartList.value.filter(res => {
				total += (Number(res.price) * res.buy_no)
			})
		}
		return total.toFixed(2)
	})
	const allNum = computed(() => {
		let totalNum = 0
		if (cartList.value.length != 0) {
			cartList.value.filter(res => {
				totalNum += res.buy_no
			})
		}
		return totalNum
	})

	const activeItem = ref(0)
	const checkShopType = (item, i) => {
		activeItem.value = i
		reqShop.poc_id = item.poc_id;
		getShop()
	}

	const shopList = ref([])
	const cartList = ref([])

	const delCart = (i) => {
		cartList.value.splice(i, 1)
	}
	const getShop = () => {
		if (!filters.value[0].value) {
			shopList.value = [];
			ElMessage.error("请选择门店！");
			return;
		}
		let data = {
			pom_id: filters.value[0].value,
			keyword: filters.value[1].value,
			poc_id: reqShop.poc_id
		}
		SaleApi.getGoodslist(data).then(res => {
			if (res.Code == 200) {
				shopList.value = res.Data;
			}
		})
	}

	const arrReduction = (arr, item) => {
		let isReduction = true;
		if (arr.length > 0) {
			for (let i = 0; i < arr.length; i++) {
				if (arr[i].goods_id === item.goods_id) {
					isReduction = false
					return arr[i]
				}
			}
		}
		return isReduction
	}
	const keyBoardRef = ref(null)
	const addCart = async (item) => {
		if (arrReduction(cartList.value, item) == true) {
			item.buy_no = 1
			cartList.value.unshift(item)
		} else {
			arrReduction(cartList.value, item).buy_no++
		}
		if (filters.value[1].value) {
			filters.value[1].value = '';
			reqShop.poc_id = '';
			activeItem.value = 0;
			setTimeout(() => {
				nextTick(() => {
					keyBoardRef.value[0].serachInputRefFocus()
				})
			})
			getShop()

		}
	}



	import aliPay from '@/assets/img/icon/alipay.png'
	import wechat from '@/assets/img/icon/wechat.png'
	import money from '@/assets/img/icon/money.png'
	import pos from '@/assets/img/icon/pos.png'
	import write from '@/assets/img/icon/write.png';
	const payType = ref([])
	// 获取支付方式
	const getOrderType = () => {
		OrderApi.getCounterPayway().then((res) => {
			if (res.Code === 200 && res.Data.length != 0) {
				payType.value = res.Data.map(resp => {
					if (resp.orp_id == '4') {
						resp.icon = aliPay
					}
					if (resp.orp_id == '2') {
						resp.icon = wechat
					}
					if (resp.orp_id == '1') {
						resp.icon = money
					}
					if (resp.orp_id == '8') {
						resp.icon = pos
					}
					if (resp.orp_id == '11') {
						resp.icon = write
					}
					return resp
				})
			} else {
				let msg = res.Message ? res.Message : "获取支付方式失败！";
				ElMessage.error(msg);
			}
		});
	};



	const centerDialogVisible = ref(false)
	const submit = async () => {
		await nextTick()
		if (allMoney.value == 0) {
			ElMessage.warning('请先选购商品')
			return
		}
		centerDialogVisible.value = !centerDialogVisible.value
	}


	const pay_way = ref('')
	const payTypeLoading = ref(false)
	const checkPayType = (item) => {
		lastPayway.value = item.orp_name;
		pay_way.value = item.orp_id;
		createOrder()
	}

	//打印
	const printInfoRef = ref(null)
	const lodopSn = () => {
		OrderApi.printOrder({
			sn: lastOrderSn.value
		}).then(res => {
			if (res.Code == 200) {
				lastOrderSn.value = "";
				printInfoRef.value.setDataAndPrint(res.Data)
			}
		})
	}

	const createOrder = () => {
		let data = {
			pay_way: pay_way.value,
			pom_id: filters.value[0].value,
			goods_list: JSON.stringify(cartList.value)
		}
		payTypeLoading.value = true
		SaleApi.addOrder(data).then(res => {
			payTypeLoading.value = false
			if (res.Code == 200) {
				lastOrderSn.value = res.Data.order_sn;
				centerDialogVisible.value = false
				if (pay_way.value == '1') {
					cashDialog.value = true;
					formLabelAlign.netReceipts = allMoney.value
				} else if (pay_way.value == '1' || pay_way.value == '8' || pay_way.value == '11') {
					succesDialog.value = true;
					startTimer()
					lodopSn()
				} else if (pay_way.value == '2' || pay_way.value == '4') {
					loadingDialog.value.show()
					setTimeout(function () {
						nextTick(() => {
							barCodeInputRef.value.focus()
						})
					})
				}
			} else {
				ElMessage.error(res.Message)
			}
		})
	}

	const loadingDialog = ref(null);
	const succesDialog = ref(false);
	const errorDialog = ref(false);
	const barCodeInputRef = ref(null);
	const barCodeInput = ref("");
	const errorTitle = ref("订单支付失败");
	const lastOrderSn = ref("");
	const lastPayway = ref(null);
	const cashDialog = ref(false)


	function barCodeInfo() {
		const lastParmas = {
			order_sn: lastOrderSn.value,
			auth_code: barCodeInput.value,
		};
		barCodeInput.value && ScenicApi.barCodeInfo(lastParmas).then((res) => {
			if (res.Code == 200) {
				if (res.Data.Code == 200) {
					barCodeInput.value = "";
					loadingDialog.value.close();
					lodopSn()
					succesDialog.value = true;
					startTimer()

				} else if (res.Data.Code == 205) {
					setTimeout(() => {
						barCodeInfo();
					}, 3000);
				} else {
					barCodeInput.value = "";
					errorTitle.value = res.Data.Message || "订单支付失败";
					loadingDialog.value.close();
					errorDialog.value = true;
					lastOrderSn.value = "";
				}
			} else {
				loadingDialog.value.close();
				lastOrderSn.value = "";
			}
		});
	}

	function CloseLoadingDialog() {
		lastOrderSn.value = "";
		barCodeInput.value = "";
		loadingDialog.value.close();
	}
	const againOrder = () => {
		errorDialog.value = false;
		centerDialogVisible.value = true;
	}
	const cancelPayOrder = () => {
		errorDialog.value = false;
		loadingDialog.value.close();
		succesDialog.value = false;
		cashDialog.value = false;
		lastOrderSn.value = "";
		centerDialogVisible.value = true;
	}
	const cancelOrder = () => {
		errorDialog.value = false
		cartList.value = [];
		succesDialog.value = false;
		cashDialog.value = false;
		loadingDialog.value.close();
		filters.value[1].value = '';
		getShop()
	}
</script>

<style lang="scss" scoped>
	$text-color: #ff7e83;
	.el-form--inline.el-form--label-top {
		display: inherit;
	}

	.skin-text-color {
		color: var(--text-color);
	}

	.margin-left {
		margin-left: 15px;
	}

	.margin {
		margin: 20px;
	}

	.img-icon {
		height: 120px;
		width: 120px;
	}

	.el-scrollbar__wrap {
		overflow-x: none !important;
	}

	.padding {
		padding: 10px;
	}

	.status-tips {
		padding: 10px 0 50px 0;
	}


	.text-red {
		color: $text-color;
	}

	.text-center {
		text-align: center;
	}

	.padding {
		padding: 10px 0;
	}

	.round {
		border-radius: 50px;
	}

	.flex-btw {
		display: flex;
		justify-content: space-between;
	}

	.grid-4 {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
	}

	.grid-5 {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
	}

	.dialog-pay {
		h2 {
			padding: 10px;
			color: $text-color;
		}


		.pay-type {
			margin-top: 20px;
			padding-bottom: 60px;
		}

		.pay-icon {
			width: 50px;
			height: 50px;
		}

	}

	.content {
		padding: 15px 20px 20px;



		.shop-card {
			background-color: var(--table-color);
			margin: 10px;
			border-radius: 10px;
			padding: 20px;

			.title {
				background-color: var(--table-color);
				padding: 5px;
				font-size: 14px;
				font-weight: bold;
				border: none;
			}

			.price {
				color: $text-color;
			}

			.img {
				display: flex;
				justify-content: center;

				img {
					height: 60px;
					width: 60px;
					border-radius: 50%;
				}
			}

			.icon {
				width: 20px;
				height: 20px;
				text-align: center;
				color: $text-color;
				margin-top: 5px;
			}
		}


		.cart {
			background-color: var(--bg-content-color);
			border-radius: 5px;
			padding: 10px 15px 0 15px;

			.el-input-number {
				width: 130px;
			}

			.border-bottom {
				border-bottom: 1px dashed #eee;
				padding: 10px 0;
			}

			.statistics {
				padding: 15px 0;

			}

			.submit {
				/* width: 100%; */
			}

			.cart-title {
				display: flex;
				justify-content: space-between;
				padding: 10px 0;
				color: var(--text-color);
			}

			.move-cur {
				transform: translateX(-100px) !important
			}

			.cart-item {
				display: flex;
				justify-content: space-between;
				padding: 10px 0;
				position: relative;
				transition: all .6s ease-in-out 0s;
				transform: translateX(0px);



				.move {
					position: absolute;
					top: 0;
					left: 0px;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #9f9f9f;
					width: 20px;
					height: 20px;
					border-radius: 50%;
					z-index: 1;
					padding: 5px;

					img {
						height: 20px;
						width: 20px;
					}
				}

				.title {
					background-color: var(--select--bg-color);
					padding: 5px;
					font-size: 14px;
					font-weight: bold;
					border: none;
				}

				.price {
					color: #ff7e83;
				}

				.img {
					display: flex;

					img {
						height: 60px;
						width: 60px;
						border-radius: 50%;
					}
				}

				.icon {
					width: 20px;
					height: 20px;
					text-align: center;
					color: #ff7e83;
					margin-top: 5px;
				}
			}
		}

		.scrollbar-flex-content {
			display: flex;
		}

		.scrollbar-item {
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px 20px;
			margin: 10px;
			text-align: center;
			background: var(--search-bg-color);
			color: var(--text-color);
		}

		.active {
			background: var(--light-red--bg-color) !important;
			color: var(--pink--text-color) !important;
		}
	}

	.select-left {
		.select-item {
			display: inline-block;
			margin-bottom: 15px;

			.el-input {
				width: 192px;
				margin-right: 15px;
			}

			.el-input__inner {
				/* background-color: var(--text-white-color) !important; */
				border-radius: 50px;
			}

			.search-input {
				width: 300px;
				margin-left: 20px;
				/* background-color: var(--text-white-color) !important; */

				.el-input__inner {
					/* background-color: var(--text-white-color) !important; */
					border-radius: 50px;
				}

				::-webkit-input-placeholder {
					color: var(--theme-color);
				}
			}

			.el-date-editor.el-input,
			.el-date-editor.el-input__inner {
				width: 280px;
			}

			.el-select .el-input__inner {
				background-color: var(--search-bg-color) !important;
				border-radius: 50px;
			}

			.el-date-editor {
				.el-input__inner {
					background-color: var(--search-bg-color);
					color: var(--theme-color);
					padding-left: 40px;
				}

				.el-input__prefix {
					.el-icon {
						color: var(--theme-color);
						font-size: 18px;
					}
				}

				.el-range-input {
					background-color: transparent;
				}
			}
		}
	}
</style>